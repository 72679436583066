<template>
    <div>
        <NavigationBar/>

        <div class="fs-single-product">
             <div class="fs-page-header">             
               <router-link to="/apps/portfolio/" > Λογισμικό</router-link>
                <h1> {{ $route.params.title }}</h1>
            </div>

            <div class="fs-product-info">
                <div class="fs-product-gallery">
                    <div v-for="item in currentProduct.photos" :key="item.id">
                        <img :src="require('../assets/' + item.source)" :alt="item.name">
                    </div>
                </div>
                <div class="fs-product-details">
                    <div class="fs-product-details-header">
                        <p class="fs-product-details-text underline">{{ currentProduct.description }}</p>
                        <span class="fs-product-details-tag">{{ currentProduct.type }}</span>
                    </div>

                    <ul class="fs-product-featurelist">
                        <li v-for="item in currentProduct.features" :key="item.id">
                            <span>{{ item.description }}</span>
                        </li>
                    </ul>
                </div>

               
            </div>

             <div class="fs-product-features" v-if="currentProduct.sections">
                    <div class="fs-product-feature" v-for="item in currentProduct.sections" :key="item.id">
                        <div class="fs-product-features-photo" data-aos="zoom-in" :data-aos-delay="item.animationDelay">
                            <img :src="require('../assets/' + item.photo)"  alt="">
                        </div>
                        <div class="fs-product-features-details">
                            <h3 class="underline">{{ item.header }}</h3>
                            <p>{{ item.description }}</p>
                        </div>
                    </div>
                </div>

                <div class="fs-product-demos" v-if="currentProduct.demos">
                    <div class="fs-product-demo" v-for="item in currentProduct.demos" :key="item.id">                   
                        <a :href="item.url" target="_blank">
                            <img :src="require('../assets/' + item.screenshot)">
                            <span class="underline">{{ item.name }}</span>
                        </a>
                    </div>
                </div>

        </div>
     
    </div>
</template>

<script>

    import NavigationBar from '@/components/NavigationBar.vue';
    import appData from '@/assets/data/CustomApplications.json';

    export default {
       name: 'SingleProduct',
       components: {
                NavigationBar
                   },
       data: function() {
           return {             
               appData: appData
           }
       },
       computed: {
        currentProduct: function () {

            var currentProductName = this.$route.params.title;

            var currentProductRow = this.appData.find((productRow) => productRow.name == currentProductName)

            return currentProductRow
        }
        },
        mounted() {  
    document.title = "Future Software - " + this.$route.params.title;  
  },
       props: {
           product : Object
       }
    }
</script>

<style scoped>
.fs-page-header {
    width:100%;
    background-image:url("../assets/heroBackground.jpeg");
    background-size:cover;
    background-position: center center;
    background-attachment: fixed;
    padding:var(--container-padding);
}

.fs-single-product {
    min-height:55rem;
}

.fs-page-header a {
    text-decoration: none;
    font-size: .95rem;
    margin: 1rem 0;
    color: white;
}

.fs-page-header h1 {
    color: white;
}

.fs-product-info {
    padding:var(--container-padding);
    display: flex;
    background:url(../assets/productBackground.jpg);
    background-size:cover;
    background-position: center center;
}

.fs-product-gallery {
    flex: none;
    width:40%;
    padding-right:var(--container-padding);
}

.fs-product-details {
    flex: auto;
}

.fs-product-details-header {
    min-height:5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:3rem;
}

.fs-product-gallery img {
    max-width:100%;
    width:auto;
    aspect-ratio: 1;
    margin-bottom:2rem;
}

.fs-product-details-tag {
    background:var(--linear-gradient);
    padding:1rem;
    color:white;
    font-size:.875rem;
    font-weight:800;
}

.fs-product-details-text {
    font-size:1.85rem;
    color:var(--text-color-primary);
    font-weight:800;
}

.fs-product-featurelist {
    padding: .75rem 2.55rem .75rem 2.55rem;
    margin-top:2rem;
    list-style:none;
     background:white;
 box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
border-top:4px solid var(--color-tertiary);
    border-radius:20px;
}

.fs-product-featurelist li {
    padding:.75rem 15px;
    color:var(--text-color-secondary);
    
}

.fs-product-featurelist li::marker {
    content: "\2611";
    font-size:1.2rem;
}

.fs-product-feature {
    display: flex;
    background: linear-gradient(90deg, rgba(120,211,244,0.3) 0%, rgba(124,98,232,0.3) 100%);
    box-shadow: inset 0px 0px 100px 70px #FFFFFF;
}

.fs-product-feature:nth-child(even) > .fs-product-features-photo {
     order: 2;
}

.fs-product-features-photo { 
    width: 35%;
    flex: none;
    padding:var(--container-padding);
}

.fs-product-features-photo img {
    width:100%;
    height:100%;
    object-fit:cover;
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
    image-rendering: crisp-edges;
}

.fs-product-features-details {
    flex:auto;
    padding:var(--container-padding);
    white-space:pre-wrap;
}

.fs-product-features-details h3 {
    font-size:1.5rem;
    font-weight:800;
    color:var(--text-color-primary);
    margin:3rem 0;
}

.fs-product-features-details p {
    line-height:1.5;
    font-size: clamp(0.75rem, 0.6667rem + 0.3704vw, 1rem);
    color:var(--text-color-primary);
}

.fs-product-demos {
    height:25rem;
    display:flex;
}

.fs-product-demo {
    flex:auto;
    max-width:50%;
    height:100%;
    overflow: hidden;
    position:relative;
}

.fs-product-demo::before {
    content:'';
    position: absolute;
    inset:0;
    z-index:2;
    background:linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
}

.fs-product-demo img {
    display: block;
    position: absolute;
    inset:0;
    z-index:1;
    object-fit: cover;
}

.fs-product-demo a {
    width:100%;
    height:100%;
    z-index:4;
}

.fs-product-demo span {
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%)
               translateY(-50%);

    z-index:3;
    color:white;
    font-size:2rem;

}

@media screen and (max-width:992px) {
    .fs-product-info {
        flex-direction: column;
    }

    .fs-product-gallery {
        margin-top:5rem;
        order:2;
        width: 100%;
    }

    .fs-product-details {
        width:100%;
    }

    .fs-product-details-header {
        flex-direction: column;
    }

    .fs-product-feature { 
        flex-direction: column;
    }

    .fs-product-feature > .fs-product-features-photo {
        width:100%;
        order: 1 !important;
    }

    .fs-product-feature > .fs-product-features-details {
        width:100%;
        order: 2 !important;
    }

    .fs-product-demos { 
        flex-direction:column;
    }
   
    .fs-product-demo { 
        max-width:100%;
        width:100%;
    }

}

</style>